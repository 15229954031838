<script lang="ts" setup>
import type { Promotion } from "@/types";

const props = defineProps<{
	bestDealOffer: Promotion;
}>();

const { t } = useT();

const emit = defineEmits<{ (event: "openCash"): void }>();

const promoOffer = computed(() => props.bestDealOffer.data?.promoOfferPreset);
const moneyOffer = computed(() => props.bestDealOffer?.data?.money);
const badgeLabel = parseJSON(promoOffer.value?.badgeLabel || "{}");
</script>
<template>
	<div class="card-wrap" data-tid="header-promo-offer" @click="emit('openCash')">
		<div class="d-flex align-items-center gap-1 mb-2">
			<ABadge background="var(--secondary-500)" variant="info" autosize>
				<AText type="xx-small" as="div" :modifiers="['bold', 'italic', 'uppercase']">
					{{ t("Best deal") }}
				</AText>
			</ABadge>
			<ABadge v-if="badgeLabel?.percent && badgeLabel?.text" variant="info" :modifiers="['outlined']" autosize>
				<AText type="xx-small" as="div" :modifiers="['bold', 'italic', 'uppercase']">
					{{ badgeLabel.percent }} {{ badgeLabel.text }}
				</AText>
			</ABadge>
		</div>
		<div class="money-box">
			<div class="money">
				<MPrizeFund
					v-if="promoOffer?.coins"
					class="prize"
					variant="entries"
					icon="12/coins"
					:icon-size="23"
					:offset="0.5"
				>
					<AText type="h6" :modifiers="['bold']">
						{{ numberFormat(promoOffer?.coins) }}
					</AText>
				</MPrizeFund>
				<MPrizeFund
					v-if="promoOffer?.entries"
					class="prize-sc"
					variant="entries"
					icon="12/secret-coins"
					:icon-size="23"
				>
					<AText type="base" :modifiers="['bold']">
						{{ numberFormat(promoOffer?.entries) }}
					</AText>
					<AText t type="xx-small" class="count-after" :modifiers="['uppercase']">
						{{ t("Free Secret Coins") }}
					</AText>
				</MPrizeFund>
			</div>
			<div class="total">
				<AText :modifiers="['uppercase']">
					{{ t("Total:") }}
				</AText>
				<AText class="money-sup" :modifiers="['bold']">
					<span v-html="numberFormatSup(Number(moneyOffer))" />
				</AText>
			</div>
		</div>
	</div>
	<AText as="p" type="base" :modifiers="['uppercase', 'bold']" class="color-neutral-50">
		{{ t("secret coins mode is on") }}
	</AText>
	<AText as="p" type="small" class="color-gray-200">
		{{ t("Grab extra SCs and start winning for real!") }}
	</AText>
	<AButton variant="primary" class="button" size="md" data-tid="promo-buy-now" @click="emit('openCash')">
		{{ t("Buy now") }}
	</AButton>
</template>

<style scoped lang="scss">
.card-wrap {
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	background-image: var(--gradient-2);
	padding: 16px;
	margin-bottom: 8px;
	position: relative;

	&:after,
	&:before {
		content: "";
		position: absolute;
		background-color: var(--secondary-500);
		opacity: 0.25;
	}

	&:after {
		left: 0;
		top: 0;
		width: 70px;
		height: 94px;
		border-bottom-right-radius: 70px 93px;
	}

	&:before {
		right: 0;
		bottom: 0%;
		width: 122px;
		height: 63px;
		border-top-left-radius: 122px 63px;
	}

	& > * {
		z-index: 1;
	}

	.label {
		margin-bottom: 8px;
		width: fit-content;
	}

	.money-box {
		display: flex;
		justify-content: space-between;
		color: var(--neutral);
	}

	.total {
		min-width: 50px;
	}

	.count-after {
		line-height: 1;
	}

	.total {
		display: flex;
		flex-direction: column;

		.money-sup {
			font-size: 18px;
			line-height: 20px;

			&:deep(sup) {
				font-size: 10px;
				top: -0.6em;
				left: 0.1em;
			}
		}
	}

	&:deep(.badge) {
		padding: 2px 4px 1px;
	}

	.prize-sc {
		gap: 4px;
	}
}

.button {
	width: 100%;
	margin-top: 8px;
}

p {
	margin: 4px 0;
}
</style>
